import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import VueMeta from 'vue-meta'
import VueCryptojs from 'vue-cryptojs'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(Vuetify)
Vue.use(VueMeta)
Vue.use(VueCryptojs)
Vue.use(VueSocialSharing)

export default new Vuetify({
    theme: {
        themes: {
            light: {
            primary: '#3FC051', // #3FC051
            secondary: '#FFFFFF', // #FFFFFF
            accent: '#3FC051', // #3FC051
            error: '#E53935',
            description: '#424242'
            },
        },
    },
});
