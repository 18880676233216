
const state = {
   history: [],
   web: false,
   addHistory:true
}

const mutations = {
    addContact(state, contact) {
        let contactToAdd = {
            id: contact.id,
            name: contact.name,
            phone: contact.phone
        }
        state.history.push(contactToAdd)
        mutations.sortHistory(state)
        window.localStorage.setItem('history', JSON.stringify(state.history))
    },
    loadHistory(state) {
        let loadedHistory = JSON.parse(localStorage.getItem("history") || "[]")
        state.history = []
        state.history = loadedHistory.map(param => {return {...param}})
        mutations.sortHistory(state)
    },
    loadHistorySettings(state) {
        let historySettingsStr = JSON.parse(localStorage.getItem("historySettings") || "true")
        if(historySettingsStr == "true" || historySettingsStr == true) {
            state.addHistory = true
        } else {
            state.addHistory = false
        }
    },
    editContact(state, contact) {
        let filterHistory = state.history.filter(item => item.id != contact.id)
        filterHistory.push(contact)
        state.history = []
        state.history = filterHistory.map(param => {return {...param}})
        mutations.sortHistory(state)
        window.localStorage.setItem('history', JSON.stringify(state.history))
    },
    deleteContact(state, id) {
        let filterHistory = state.history.filter(contact => contact.id != id)
        state.history = []
        state.history = filterHistory.map(param => {return {...param}})
        mutations.sortHistory(state)
        window.localStorage.setItem('history', JSON.stringify(state.history))
    },
    sortHistory(state) {
        state.history.sort(function(x, y) {
            return y.id - x.id;
        })
    },
    setWeb(state, value) {
        state.web = value
    },
    setAddHistory(state, value) {
        state.addHistory = value
        if(value) {
            window.localStorage.setItem('historySettings', JSON.stringify("true"))
        } else {
            window.localStorage.setItem('historySettings', JSON.stringify("false"))
        }
    }
    
}

const actions = {
   addContact({ commit }, contact) {
       commit('addContact', contact)
   },
   loadHistory({ commit }) {
       commit('loadHistory')
   },
   deleteContact({ commit }, id) {
        commit('deleteContact', id)
   },
   editContact({ commit }, contact) {
       commit('editContact', contact)
   },
   setWeb({ commit }, value) {
       commit('setWeb', value)
   },
   setAddHistory({ commit }, value) {
    commit('setAddHistory', value)
    },
    loadHistorySettings({ commit }) {
        commit('loadHistorySettings')
    }
}

const getters = {
    history: (state) => {
        return state.history
    },
    web: (state) => {
        return state.web
    },
    addHistory: (state) => {
        return state.addHistory
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}