import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueTelInput from 'vue-tel-input'
import VueGtag from "vue-gtag";
import 'vue-tel-input/dist/vue-tel-input.css'

Vue.use(VueTelInput)

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-7YG3Y50ZCM" }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
