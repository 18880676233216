<template>
    <div>
        <div class="mt-8 mx-auto px-4 text-center" style="max-width:800px;">
            <br/><br/>
            <v-avatar class="mx-auto" size="100">
            <img
                src="https://quickwapp.com/img/logo.d18d4293.png">
            </v-avatar>
            <h3 class="mt-4">QuickWApp.com</h3>
            <h4 class="mt-12">Ooops</h4>
            <h5 class="mt-2">Page not found...</h5>
            <v-btn 
                class="mt-2"
                color="primary"
                to="/"
                text>GO TO HOME PAGE</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>