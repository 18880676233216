import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import QuickWAppRedirect from '../views/QuickWAppRedirect.vue'
import PageNotFound from '../views/PageNotFound.vue'
//import Page from '../views/Page.vue'
//import CreateLink from '../views/CreateLink.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/QWAR/:phone',
    name: 'QuickWAppRedirect',
    component: QuickWAppRedirect
  },
  // {
  //   path: '/CreateLink',
  //   name: 'CreateLink',
  //   component: CreateLink
  // },
  // {
  //   path: '/Page/:page',
  //   name: 'page',
  //   component: Page
  // },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
