<template>
  <div>
    <MainAppbar/>
    <br/><br/>
    <br class="hidden-md-and-down"/>
    <InstallPWA class="mx-auto mt-2" style="max-width:500px;"/>
    
    <div class="mt-6 mx-auto px-4" style="max-width:500px;">
      <template v-if="chat">
        <Chat/>
        <History 
          class="mt-4 mx-auto"
          v-if="history.length"/>
      </template>
      <template v-else>
        <Generate/>
      </template>
      <v-img
        class="mx-auto mt-6"
        max-width="500"
        max-height="500"
        cover
        @click="openGooglePlay"
        src="@/assets/elections2024.png"
      ></v-img>
      <PrivacyPolicyBanner v-if="alertVisible" class="mt-6 mx-auto"/>
      <v-col
        class="text-center"
        cols="12">
          <div class="text-left footer mb-2">
            <v-btn to="/privacypolicy" x-small color="grey" text>
              Privacy Policy
            </v-btn>
          </div>
      </v-col>
    </div>
  </div>
</template>

<script>
  import MainAppbar from '@/components/Appbar/MainAppbar.vue'
  import InstallPWA from '@/components/Extra/InstallPWA.vue'
  import Chat from '@/components/Chat/Chat.vue'
  import History from '@/components/Chat/History.vue'
  import PrivacyPolicyBanner from '@/components/Extra/PrivacyPolicyBanner.vue'
  import Generate from '@/components/Generate/Generate.vue'
  import { mapActions, mapGetters } from 'vuex'
  import gtagAnalytics from '@/plugins/gtagAnalytics.js' 

  export default {
    name: 'Home',
    data() {
      return {
        alertVisible: false,
        imageSrc: '',

      }
    },
    components: {
      MainAppbar,
      InstallPWA,
      Chat,
      History,
      PrivacyPolicyBanner,
      Generate
    },
    computed: {
        ...mapGetters('storeHistory', ['history']),
        ...mapGetters('storeApp', ['chat'])
    },
    methods: {
      ...mapActions('storeHistory', ['loadHistory', 'loadHistorySettings']),
      openGooglePlay() {
        gtagAnalytics.sendEvent(this.$gtag, 'Click Elections')
        window.open('https://play.google.com/store/apps/details?id=com.geckoapps.elections', '_blank') 
      }
    },
    created() {
      this.loadHistory()
      this.loadHistorySettings()
      this.alertVisible = (localStorage.getItem('privacypolicyalert') == null || localStorage.getItem('privacypolicyalert') == 'true')
      gtagAnalytics.sendPageView(this.$gtag, this.$route)
    }
  }
</script>


<style scoped>

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 10px;
}


</style>