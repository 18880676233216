<template>
    <div>
        <div class="center-screen text-center mt-n12" style="max-width:800px;">
            <LoadingAnim :showLoading="showLoading"/>
        </div>
        
        <div class="center-screen" style="max-width:800px;">
            <br/><br/>
            <div class="text-center" v-if="!showLoading">
                <div class="caption font-weight-medium mt-4">Open chat with</div>
                <div class="mt-2">
                    <v-btn 
                        class="mr-2 text-capitalize white--text" 
                        x-small 
                        color="primary" 
                        @click="whatsAppMobile">WhatsApp
                    </v-btn>
                    <span class="caption font-weight-medium"> or </span>
                    <v-btn 
                        class="ml-2 text-capitalize white--text" 
                        x-small 
                        @click="whatsAppWeb"
                        color="primary">WhatsApp Web
                    </v-btn>
                </div>
                
            </div>
            
        </div>
        <Footer class="footer"/>
    </div>
</template>

<script>
import LoadingAnim from '@/components/Extra/LoadingAnim'
import Footer from '@/components/Extra/Footer'
import gtagAnalytics from '@/plugins/gtagAnalytics.js' 

export default {
    name: 'QuickWAppRedirect',
    data() {
        return {
            phoneNumber: '',
            showLoadingAnim: true,
        }
    },
    computed: {
        showLoading: {
            set(value) {
                this.showLoadingAnim = value
            },
            get() {
                return this.showLoadingAnim
            }
        },
    },
    methods: {
        loadPhoneNumber() {
            let encodedPhone = this.$route.params.phone
            this.phoneNumber = window.atob(encodedPhone)
            setTimeout(this.showActions, 4000)
        },
        showActions() {
            this.showLoading = false
        },
        whatsAppWeb() {
            console.log('WhatsApp WEB')
            gtagAnalytics.sendEvent(this.$gtag, 'SendMsg-QWAR-WEB')
            window.open('https://web.whatsapp.com/send?phone=' + this.phoneNumber, '_blank') 
        },
        whatsAppMobile() {
            console.log('WhatsApp Mobile')
            gtagAnalytics.sendEvent(this.$gtag, 'SendMsg-QWAR-Mobile')
            window.open('whatsapp://send?phone=' + this.phoneNumber, '_blank')
        },
    },
    components: {
        LoadingAnim,
        Footer
    },
    created() {
        this.loadPhoneNumber()
        gtagAnalytics.sendPageView(this.$gtag, '/qwar')
    },

}
</script>

<style scoped>

.center-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.footer {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
}

</style>