<template>
  <div class="pa-4">
    <BackAppbar/>
    <br/>
    <h1 class="mt-6">Privacy Policy</h1>
    <br/>
    <h5 class="font-weight-regular">As a visitor to this website please assume that your IP address is logged by myself and any ads you click. Submitting forms that include your email address may expose your private data to 3rd party companies.</h5>
    <br/>
    <h5 class="font-weight-regular">QuickWApp found at https://quickwapp.com/ (“Website”) is governed by the following privacy policy (“Privacy Policy”).</h5>
    <br/>
    <h5 class="font-weight-regular">We respect your privacy and are committed to protecting it. The purpose of this Privacy Policy is to inform you what personally identifiable information we may collect and how it may be used. This statement only applies to this Website.</h5>
    <br/>
    <h5 class="font-weight-regular">GDPR</h5>

    <br/>
    <h4>Who we are</h4>
    <h5 class="font-weight-regular">Our website address is: https://QuickWApp.com</h5>

    <br/>
    <h4>What personal data we collect and why we collect it</h4>
    <h5 class="mt-2">Cookies</h5>
    <h5 class="font-weight-regular">When you log in, we will also set up several cookies to save your login information and your screen display choices. screen options cookies last for a year.</h5>

    <h5 class="mt-2">Local Storage</h5>
    <h5 class="font-weight-regular">Local Storage sometimes known as DOM storage, provides web apps with methods and protocols for storing client-side data. Web storage supports persistent data storage, similar to cookies but with a greatly enhanced capacity and no information stored in the HTTP request header.</h5>

    <h5 class="mt-2">Sessions</h5>
    <h5 class="font-weight-regular">We use "Sessions" to identify the areas of our website that you have visited. A Session is a small piece of data stored on your computer or mobile device by your web browser.</h5>

    <h5 class="mt-2">Analytics</h5>
    <h5 class="font-weight-regular">We use Google Analytics and collect information about your session on our website.</h5>

    <br/>
    <h4>Who we share your data with</h4>
    <h5 class="font-weight-regular">We don't share any data with third parties, however, some services we such as display ads or plugins may collect data and share it with third parties.</h5>

    <br/>
    <h4>Your contact information</h4>
    <h5 class="font-weight-regular">contact us at geckoappsco@gmail.com</h5>

    <br/>
    <h4>OTHER</h4>
    <h5 class="font-weight-regular">Automatically-Collected Information: We automatically collect certain information about you and the device with which you access the Website. For example, when you use the Website, we will log your IP address, operating system type, browser type, referring website, pages you viewed, and the dates/times when you accessed the Website. We may also collect information about actions you take when using the Website, such as links clicked.</h5>

    <h5 class="mt-2">Cookies:</h5>
    <h5 class="font-weight-regular">We may log information using cookies, which are small data files stored on your browser by the Website. We may use both session cookies, which expire when you close your browser, and persistent cookies, which stay on your browser until deleted, to provide you with a more personalized experience on the Website.</h5>


    <br/>
    <h4>HOW YOUR INFORMATION MAY BE USED</h4>
    <h5 class="font-weight-regular">To provide you with user support; To track and measure advertising on the Website; To protect, investigate, and deter against unauthorized or illegal activity.</h5>


    <br/>
    <h4>THIRD-PARTY USE OF PERSONAL INFORMATION</h4>
    <h5 class="font-weight-regular">We may share your information with third parties when you explicitly authorize us to share your information.</h5>
    <h5 class="font-weight-regular">Additionally, the Website may use third-party service providers to service various aspects of the Website. Each third-party service provider’s use of your personal information is dictated by their respective privacy policies.</h5>
    <h5 class="font-weight-regular">The Website currently uses the following third-party service providers:</h5>
    <br/>
    <h5 class="font-weight-regular"><strong>Google Analytics</strong> – this service tracks Website usage and provides information such as referring websites and user actions on the Website. Google Analytics may capture your IP address, but no other personal information is captured by Google Analytics.</h5>
    <br/>
    <h5 class="font-weight-regular">At this time, your personal information is not shared with any other third-party applications. This list may be amended from time to time in the Website’s sole discretion.</h5>
    <br/>
    <h5 class="font-weight-regular">Except when required by law, we will not sell, distribute, or reveal your email addresses or other personal information without your consent; however, we may disclose or transfer personal information collected through the Website to third parties who acquire all or a portion of our business, which may be the result of a merger, consolidation, or purchase of all or a portion of our assets, or in connection with any bankruptcy or reorganization proceeding brought by or against us.</h5>

    <br/>
    <h4>ANONYMOUS DATA</h4>
    <h5 class="font-weight-regular">From time to time, we may use anonymous data, which does not identify you alone, or when combined with data from other parties. This type of anonymous data may be provided to other parties for marketing, advertising, or other uses. Examples of this anonymous data may include analytics or information collected from cookies and links clicked.</h5>

    <br/>
    <h4>COOKIES</h4>
    <h5 class="font-weight-regular">The Website uses cookies/local-storage to store visitors preferences, record user-specific information on what pages users access or visit, ensure that visitors are not repeatedly sent the same banner ads, customize Website content based on visitors browser type or other information that the visitor sends. Cookies/local-storage may also be used by third-party services, such as Google Analytics, as described herein.</h5>
    <br/>
    <h5 class="font-weight-regular">Users may, at any time, prevent the setting of cookies/local-storage, by the Website, by using a corresponding setting of your internet browser and may thus permanently deny the setting of cookies/local-storage. Furthermore, already set cookies/local-storage may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. However, if users deactivate the setting of cookies/local-storage in your Internet browser, not all functions of our Website may be entirely usable.</h5>

    <br/>
    <h4>ADVERTISING</h4>
    <h5>Display Ads</h5>
    <h5 class="font-weight-regular">We may use third-party advertising companies to serve content and advertisements when you visit the Website, which may use cookies, as noted above.</h5>

    <br/>
    <h4>Affiliate Program Participation</h4>
    <h5 class="font-weight-regular">The Website may engage in affiliate marketing, which is done by embedding tracking links into the Website. If you click on a link for an affiliate partnership, a cookie will be placed on your browser to track any sales for purposes of commissions.</h5>

    <br/>
    <h4>SENSITIVE PERSONAL INFORMATION</h4>
    <h5 class="font-weight-regular">At no time should you submit sensitive personal information to the Website. This includes your social security number, information regarding race or ethnic origin, political opinions, religious beliefs, health information, criminal background, or trade union memberships. If you elect to submit such information to us, it will be subject to this Privacy Policy.</h5>


    <br/>
    <h4>CHILDREN'S INFORMATION</h4>
    <h5 class="font-weight-regular">The Website does not knowingly collect any personally identifiable information from children under the age of 16. If a parent or guardian believes that the Website has personally identifiable information of a child under the age of 16 in its database, please contact us immediately at geckoappsco@gmail.com and we will use our best efforts to promptly remove such information from our records.</h5>

    <br/>
    <h4>CONTACT INFORMATION</h4>
    <h5 class="font-weight-regular">At any time, please contact us at geckoappsco@gmail.com for questions related to this Privacy Policy.</h5>


  </div>
</template>

<script>
import BackAppbar from '@/components/Appbar/BackAppbar.vue'
import gtagAnalytics from '@/plugins/gtagAnalytics.js' 

export default {
  components: {
    BackAppbar
  },
  created() {
    gtagAnalytics.sendPageView(this.$gtag, this.$route)
  }
}
</script>
