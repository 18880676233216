import Vue from 'vue'
import Vuex from 'vuex'

import storeHistory from './store-history'
import storeApp from './store-app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    storeHistory,
    storeApp
  }
})
