
const state = {
    chat: true
 }
 
 const mutations = {
    setChat(state, value) {
        state.chat = value
    }
 }
 
 const actions = {
    setChat({ commit }, value) {
        commit('setChat', value)
    }
 }
 
 const getters = {
     chat: (state) => {
         return state.chat
     }
 }
 
 export default {
     namespaced: true,
     state,
     mutations,
     actions,
     getters
 }