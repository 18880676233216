export default {

    sendEvent(gtag, event) {
        gtag.event(event, {
            'event_category' : event + '-category',
            'event_label' : event + '-label'
        })
    },

    sendPageView(gtag, page) {
        gtag.pageview(page)
    }

}